import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import EmailVerificationProtected from "../components/EmailVerificationProtected";
import TermsAgreementProtected from "../components/TermsAgreementProtected";
import {
  FaAngleRight,
  FaFeatherAlt,
  FaLeaf,
  FaLightbulb,
} from "react-icons/fa";

export default function Home() {
  return (
    <TermsAgreementProtected>
      <EmailVerificationProtected>
        <Container>
          <VStack
            w="100%"
            justifyContent={"center"}
            alignItems={"center"}
            minHeight="calc(100vh - 128px)"
          >
            <HStack w="100%">
              <Link to="/reflection/questions" style={{ width: "100%" }}>
                <Card w="100%">
                  <Button
                    variant={"ghost"}
                    w="100%"
                    py="25"
                    colorScheme="yellow"
                  >
                    <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                      <GridItem
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <FaFeatherAlt />
                      </GridItem>
                      <GridItem display={"flex"} justifyContent={"center"}>
                        질문성찰
                      </GridItem>
                      <GridItem
                        fontSize={20}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <FaAngleRight />
                      </GridItem>
                    </Grid>
                  </Button>
                </Card>
              </Link>
              <Link to="/reflection/experiences" style={{ width: "100%" }}>
                <Card w="100%">
                  <Button
                    variant={"ghost"}
                    w="100%"
                    py="25"
                    colorScheme="green"
                  >
                    <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                      <GridItem
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <FaLeaf />
                      </GridItem>
                      <GridItem display={"flex"} justifyContent={"center"}>
                        경험성찰
                      </GridItem>
                      <GridItem
                        fontSize={20}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <FaAngleRight />
                      </GridItem>
                    </Grid>
                  </Button>
                </Card>
              </Link>
            </HStack>
            <Divider my="5" />
            <Link to="/my/reflections" style={{ width: "100%" }}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <FaLightbulb />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      나의 성찰
                    </GridItem>
                    <GridItem
                      fontSize={20}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <FaAngleRight />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
            <Link to="/my/pursuits" style={{ width: "100%" }}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <FaLightbulb />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      나의 추구
                    </GridItem>
                    <GridItem
                      fontSize={20}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <FaAngleRight />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
            <Link style={{ width: "100%" }} to={"/todolist"}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <FaLightbulb />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      나의 실천
                    </GridItem>
                    <GridItem
                      fontSize={20}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <FaAngleRight />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
          </VStack>
        </Container>
      </EmailVerificationProtected>
    </TermsAgreementProtected>
  );
}

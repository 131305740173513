import { Text } from "@chakra-ui/react";

export default function PositiveValues() {
  return (
    <Text textAlign={"center"} mt="5" lineHeight={2}>
      사랑 평화 화합 조화 연결 <br />
      정의 평등 관용 존중 공존 <br />
      창조 혁신 탐구 탐험 창의성 <br />
      성장 변화 도전 강인함 유연함 <br />
      온전함 정교함 통찰 지혜 독창성 <br />
      신뢰 연대 협동 헌신 희생 <br />
      겸손 정직 책임감 용서 자기수양 <br />
      인내 강인함 배려 공감 친절 <br />
      감화 인정 나눔 감사 <br />
      자유 자율성 평정심 안정 참신함
    </Text>
  );
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  Input,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getReflectionQuestion,
  IUploadPosQuestionReflectionError,
  IUploadPosQuestionReflectionSuccess,
  IUploadPosQuestionReflectionVariables,
  uploadPosQuestionReflection,
} from "./api";
import React, { useEffect, useState } from "react";
import PositiveEmotions from "../components/PositiveEmotions";
import PositiveValues from "../components/PositiveValues";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import ProtectedPage from "../components/ProtectedPage";

interface ISubQuestion {
  pk: number;
  text: string;
  order: number;
  is_positive_emotion: boolean;
  is_positive_value: boolean;
  is_negative_emotion: boolean;
  is_negative_value: boolean;
}

interface IQuestionDetail {
  title: string;
  sub_questions: ISubQuestion[];
  type: "positive" | "negative";
}

interface IForm {
  [key: `subAnswer${number}`]: string;
  keywordPositiveValue: string;
  keywordPositiveEmotion: string;
  keywordActivity: string;
  title: string;
}

export default function UploadPosQuestionReflection() {
  const { register, watch, handleSubmit } = useForm<IForm>();
  const [tabIndex, setTabIndex] = useState(0);
  const { pk } = useParams();
  const { isLoading, data: posReflectionQuestion } = useQuery<IQuestionDetail>({
    queryKey: ["posReflectionQuestion", pk],
    queryFn: getReflectionQuestion,
  });
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IUploadPosQuestionReflectionSuccess,
    IUploadPosQuestionReflectionError,
    IUploadPosQuestionReflectionVariables
  >({
    mutationFn: uploadPosQuestionReflection,
    onSuccess: () => {
      toast({
        title: `성찰이 업로드 되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      navigate("/reflection/questions");
    },
    onError: (error) => console.log(error),
  });

  const location = useLocation();
  const pathType = location.pathname.split("/")[4];
  useEffect(() => {
    if (posReflectionQuestion) {
      if (posReflectionQuestion.type !== pathType) {
        navigate("/reflection/questions/select");
      }
    }
  }, [posReflectionQuestion, navigate, pathType]);

  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const inputPositiveValue = watch("keywordPositiveValue");
  const inputPositiveEmotion = watch("keywordPositiveEmotion");
  const inputActivity = watch("keywordActivity");

  const onSubmit = (formData: IForm) => {
    if (pk) {
      mutation.mutate({ formData, pk: parseInt(pk) });
    }
  };

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/questions/select`);
  };

  return (
    <ProtectedPage>
      <Container>
        <Flex
          py="5"
          px="1"
          onClick={onCancelAlertOpen}
          _hover={{ cursor: "pointer" }}
        >
          <Icon as={FaChevronLeft} />
        </Flex>
        <AlertDialog
          isCentered
          isOpen={isCancelAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCancelAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                질문성찰 닫기
              </AlertDialogHeader>
              <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCancelAlertClose}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={handleCancel} ml={3}>
                  닫기
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        {isLoading ? (
          <>
            <VStack spacing={"3"}>
              <Skeleton w="30vw" h="40px" borderRadius={"lg"} />
              <Skeleton w="100%" h="30px" borderRadius={"lg"} />
              <Skeleton w="90%" h="300px" borderRadius={"lg"} />
              <Grid gridTemplateColumns={"1fr 1fr 1fr"} gap="5" w="90%">
                <Skeleton w="100%" h="40px" borderRadius={"lg"} />
                <Skeleton w="100%" h="40px" borderRadius={"lg"} />
                <Skeleton w="100%" h="40px" borderRadius={"lg"} />
              </Grid>
            </VStack>
          </>
        ) : (
          <VStack>
            {posReflectionQuestion ? (
              <>
                <Heading>{posReflectionQuestion?.title}</Heading>
                <Tabs
                  w="100%"
                  isFitted
                  index={tabIndex}
                  onChange={(index) => setTabIndex(index)}
                >
                  <TabList>
                    {posReflectionQuestion?.sub_questions.map(
                      (sub_question, index) => (
                        <Tab key={index}>{index + 1}</Tab>
                      )
                    )}
                    <Tab>{posReflectionQuestion?.sub_questions.length + 1}</Tab>
                  </TabList>
                  <TabPanels as="form" onSubmit={handleSubmit(onSubmit)}>
                    {posReflectionQuestion?.sub_questions.map(
                      (sub_question, index) => (
                        <TabPanel key={index}>
                          <VStack>
                            <Card w="100%">
                              <CardBody>
                                <VStack>
                                  <Text
                                    textAlign="center"
                                    fontSize={18}
                                    fontWeight={700}
                                    wordBreak="keep-all"
                                    whiteSpace="normal"
                                  >
                                    {sub_question.text}
                                  </Text>
                                  <Textarea
                                    h="200px"
                                    resize="vertical"
                                    {...register(`subAnswer${sub_question.pk}`)}
                                  />
                                  {sub_question.is_positive_emotion ? (
                                    <PositiveEmotions />
                                  ) : sub_question.is_positive_value ? (
                                    <PositiveValues />
                                  ) : null}
                                </VStack>
                              </CardBody>
                            </Card>
                            <Grid
                              w="100%"
                              gridTemplateColumns={"1fr 1fr 1fr"}
                              gap="2"
                            >
                              {index === 0 ? (
                                <Box></Box>
                              ) : (
                                <Button
                                  onClick={() => setTabIndex(index - 1)}
                                  fontSize="24"
                                >
                                  ←
                                </Button>
                              )}
                              <Button
                                fontSize="16"
                                type="submit"
                                isLoading={mutation.isPending}
                              >
                                저장
                              </Button>
                              <Button
                                onClick={() => setTabIndex(index + 1)}
                                fontSize="24"
                              >
                                →
                              </Button>
                            </Grid>
                          </VStack>
                        </TabPanel>
                      )
                    )}
                    <TabPanel>
                      <VStack>
                        <Card w="100%">
                          <CardBody>
                            <VStack>
                              <Text
                                fontSize={18}
                                fontWeight={700}
                                textAlign={"center"}
                              >
                                다음의 3가지 키워드를 완성해보세요!
                              </Text>
                              <Divider />
                              <FormControl>
                                <FormLabel>나에게 의미있는 가치</FormLabel>
                                <Input
                                  textAlign={"center"}
                                  placeholder="ex) 감각적 아름다움, 서사"
                                  {...register("keywordPositiveValue")}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>
                                  그 가치에 내가 느끼는 감정
                                </FormLabel>
                                <Input
                                  textAlign={"center"}
                                  placeholder="ex) 신남, 아련함, 몰입감"
                                  {...register("keywordPositiveEmotion")}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>
                                  그 가치를 실천할 수 있는 활동들
                                </FormLabel>
                                <Input
                                  textAlign={"center"}
                                  placeholder="ex) 노래 듣기, 소설 읽기, 영화 감상, 미술 전시회 감상"
                                  {...register("keywordActivity")}
                                />
                              </FormControl>
                            </VStack>
                          </CardBody>
                        </Card>
                        <Card w="100%">
                          <CardBody>
                            <VStack>
                              <Text
                                fontSize={20}
                                fontWeight={700}
                                textAlign={"center"}
                              >
                                나의 성찰
                              </Text>
                              <Divider />
                              <Text
                                textAlign={"center"}
                                lineHeight={2}
                                wordBreak="keep-all"
                                whiteSpace="normal"
                              >
                                나는{" "}
                                <Text
                                  as="span"
                                  my="3"
                                  fontSize={18}
                                  fontWeight={"700"}
                                  bg={highlightColor}
                                >
                                  {inputPositiveValue
                                    ? inputPositiveValue
                                    : "감각적 아름다움, 서사"}
                                </Text>{" "}
                                에{" "}
                                <Text
                                  as="span"
                                  my="3"
                                  fontSize={18}
                                  fontWeight={"700"}
                                  bg={highlightColor}
                                >
                                  {inputPositiveEmotion
                                    ? inputPositiveEmotion
                                    : "신남, 아련함, 몰입감"}
                                </Text>{" "}
                                을(를) 느낀다.
                              </Text>
                            </VStack>
                          </CardBody>
                        </Card>
                        <Card w="100%">
                          <CardBody>
                            <VStack>
                              <Text
                                fontSize={20}
                                fontWeight={700}
                                textAlign={"center"}
                              >
                                나의 추구
                              </Text>
                              <Divider />
                              <Text
                                textAlign={"center"}
                                lineHeight={2}
                                wordBreak="keep-all"
                                whiteSpace="normal"
                              >
                                나는{" "}
                                <Text
                                  as="span"
                                  my="3"
                                  fontSize={18}
                                  fontWeight={"700"}
                                  bg={highlightColor}
                                >
                                  {inputActivity ||
                                    "노래 듣기, 소설 읽기, 영화 감상, 미술 전시회 감상"}
                                </Text>{" "}
                                을(를) 통해{" "}
                                <Text
                                  as="span"
                                  my="3"
                                  fontSize={18}
                                  fontWeight={"700"}
                                  bg={highlightColor}
                                >
                                  {inputPositiveValue ||
                                    "감각적 아름다움, 서사"}
                                </Text>{" "}
                                을(를) 추구한다.
                              </Text>
                            </VStack>
                          </CardBody>
                        </Card>
                        <Card w="100%">
                          <CardBody>
                            <VStack>
                              <Text
                                fontSize={18}
                                fontWeight={700}
                                textAlign={"center"}
                              >
                                이번 성찰의 제목을 작성해주세요!
                              </Text>
                              <Input
                                textAlign={"center"}
                                placeholder="긍정경험 성찰"
                                {...register("title")}
                              />
                            </VStack>
                          </CardBody>
                        </Card>
                        <Grid
                          w="100%"
                          gridTemplateColumns={"1fr 1fr 1fr"}
                          gap="2"
                        >
                          <Button
                            fontSize="24"
                            onClick={() =>
                              setTabIndex(
                                posReflectionQuestion.sub_questions.length - 1
                              )
                            }
                          >
                            ←
                          </Button>
                          <Button
                            type="submit"
                            isLoading={mutation.isPending}
                            fontSize="16"
                          >
                            저장
                          </Button>
                        </Grid>
                      </VStack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </>
            ) : null}
          </VStack>
        )}
      </Container>
    </ProtectedPage>
  );
}

import {
  Heading,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FaLeaf } from "react-icons/fa";

import { PuffLoader } from "react-spinners";

export default function LoadingScreen() {
  const spinnerColor = useColorModeValue("#36454F", "#FFFACD");
  const bgColor = useColorModeValue("white", "gray.800");
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"center"}
      w="100vw"
      minH="100vh"
      bg={bgColor}
    >
      <PuffLoader color={spinnerColor} size={80} />
      <Heading fontWeight={900}>BeSelf</Heading>
      <Text>나 자신으로 살아갈 오늘을 응원합니다!</Text>
      <Icon as={FaLeaf} w="6" h="6" mt="4" color="green.300" />
    </VStack>
  );
}

import { Divider, Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";

export default function SubTaskListSkeleton() {
  return (
    <>
      <Flex py="2" justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          <SkeletonCircle mr="2" size="4" />
          <Skeleton h="20px" w="200px" />
        </Flex>
      </Flex>
      <Divider />
    </>
  );
}

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IPositiveReflectionKeyword, IReflectionDetail } from "../types";
import {
  deletePosQuestionReflection,
  getPosQuestionReflection,
  getReflectionQuestion,
  IDeletePosQuestionReflectionError,
  IDeletePosQuestionReflectionVariables,
} from "./api";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FaChevronLeft, FaPencilAlt, FaTrash } from "react-icons/fa";

interface ISubQuestion {
  pk: number;
  text: string;
  order: number;
}

interface IQuestionDetail {
  title: string;
  sub_questions: ISubQuestion[];
  type: "positive" | "negative";
}

export default function PosQuestionReflectionDetail() {
  const { questionPk, reflectionPk } = useParams();
  const { isLoading: isQuestionLoading, data: question } =
    useQuery<IQuestionDetail>({
      queryKey: ["reflectionQuestion", questionPk],
      queryFn: getReflectionQuestion,
    });
  const { isLoading: isReflectionLoading, data: reflection } = useQuery<
    IReflectionDetail,
    Error
  >({
    queryKey: ["questionReflection", reflectionPk],
    queryFn: getPosQuestionReflection,
  });

  const navigate = useNavigate();
  const mutation = useMutation<
    any,
    IDeletePosQuestionReflectionError,
    IDeletePosQuestionReflectionVariables
  >({
    mutationFn: deletePosQuestionReflection,
    onSuccess: () => {
      navigate("/reflection/questions");
    },
  });

  const location = useLocation();
  const pathType = location.pathname.split("/")[4];
  useEffect(() => {
    if (question) {
      if (question.type !== pathType) {
        navigate("/reflection/questions");
      }
    }
  }, [question, navigate, pathType]);

  const isLoading = isQuestionLoading || isReflectionLoading;
  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const handleDelete = () => {
    if (reflectionPk) {
      mutation.mutate({ PosQuestionReflectionPk: parseInt(reflectionPk) });
    }
  };

  return (
    <Container mb="10">
      <Flex>
        <Link to={`/reflection/questions`}>
          <Flex py="5" px="1">
            <Icon as={FaChevronLeft} />
          </Flex>
        </Link>
      </Flex>
      {isLoading ? (
        <VStack>
          <Skeleton w="80%" h="30px" borderRadius={"lg"} />
          <Skeleton w="100%" h="600px" borderRadius={"lg"} />
          <Skeleton w="100%" h="400px" borderRadius={"lg"} />
        </VStack>
      ) : (
        <VStack>
          <Heading>{reflection?.title}</Heading>
          <Card w="100%">
            <CardBody>
              <Text fontSize={"22"} textAlign={"center"} fontWeight={700}>
                {question?.title}
              </Text>
              {question?.sub_questions.map((subQuestion, index) => (
                <Box key={index}>
                  <Divider my="3" />
                  <Text>{subQuestion.text}</Text>
                  {reflection?.sub_answers
                    .filter(
                      (subAnswer) =>
                        subAnswer.sub_question_pk === subQuestion.pk
                    )
                    .map((subAnswer, index) => (
                      <Text key={index} mt="2" fontWeight={700}>
                        → {subAnswer.text}
                      </Text>
                    ))}
                </Box>
              ))}
            </CardBody>
          </Card>
          <Card w="100%">
            <CardBody>
              <Text textAlign={"center"} fontSize={"20"} fontWeight={900}>
                성찰 키워드
              </Text>
              <Divider my="3" />
              <Text>나에게 의미있는 긍정가치</Text>
              <Text fontWeight={700}>
                → "{reflection?.reflection_keyword.positive_value}"
              </Text>
              <Divider my="3" />
              <Text mt="3">긍정가치에 느끼는 감정</Text>
              <Text fontWeight={700}>
                → "{reflection?.reflection_keyword.positive_emotion}"
              </Text>
              <Divider my="3" />
              <Text mt="3">긍정가치를 실천할 수 있는 활동들</Text>
              <Text fontWeight={700}>
                → "{reflection?.reflection_keyword.activity}"
              </Text>
            </CardBody>
          </Card>
          <Card w="100%">
            <CardBody>
              <VStack>
                <Text fontSize={20} fontWeight={900} textAlign={"center"}>
                  나의 성찰
                </Text>
                <Divider />
                <Text textAlign={"center"} lineHeight={2}>
                  나는{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {reflection?.reflection_keyword.positive_value}
                  </Text>{" "}
                  에{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {reflection?.reflection_keyword.positive_emotion}
                  </Text>{" "}
                  을(를) 느낀다.
                </Text>
              </VStack>
            </CardBody>
          </Card>
          <Card w="100%">
            <CardBody>
              <VStack>
                <Text fontSize={20} fontWeight={900} textAlign={"center"}>
                  나의 추구
                </Text>
                <Divider />
                <Text textAlign={"center"} lineHeight={2}>
                  나는{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {reflection?.reflection_keyword.activity}
                  </Text>{" "}
                  을(를) 통해{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {reflection?.reflection_keyword.positive_value}
                  </Text>{" "}
                  을(를) 추구한다.
                </Text>
              </VStack>
            </CardBody>
          </Card>
          <Link to="edit" style={{ width: "100%" }}>
            <Button w="100%" colorScheme="green">
              <Icon as={FaPencilAlt} />
            </Button>
          </Link>
          <Divider my="5" />
          <Button w="100%" colorScheme="red" onClick={onOpen}>
            <Icon as={FaTrash} />
          </Button>
          <AlertDialog
            isCentered
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  "{reflection?.title}" 삭제
                </AlertDialogHeader>
                <AlertDialogBody>
                  <Text>정말로 성찰을 삭제하시겠습니까?</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    아니요. 돌려주세요.
                  </Button>
                  <Button
                    isLoading={mutation.isPending}
                    colorScheme="red"
                    onClick={handleDelete}
                    ml={3}
                  >
                    네. 확신합니다.
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </VStack>
      )}
    </Container>
  );
}

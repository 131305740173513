import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight, FaChevronLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editPosExpReflection,
  getPositiveExperience,
  IEditPosExpReflectionError,
  IEditPosExpReflectionSuccess,
  IEditPosExpReflectionVariables,
} from "./api";
import { IPositiveExperienceReflection } from "../types";

interface IForm {
  title: string;
  positiveExperience: string;
  specificEmotion: string;
  situationAnalysis: string;
  coreValue: string;
  similarExperience: string;
  relatedActivity: string;
  keywordPositiveValue: string;
  keywordPositiveEmotion: string;
  keywordActivity: string;
}

export default function EditPosExperience() {
  const { pk } = useParams();
  const { isLoading, data: positiveReflectionData } = useQuery<
    IPositiveExperienceReflection,
    Error
  >({
    queryKey: ["positiveExperiences", pk],
    queryFn: getPositiveExperience,
  });

  const { register, watch, handleSubmit, reset } = useForm<IForm>();

  useEffect(() => {
    if (positiveReflectionData) {
      reset({
        title: positiveReflectionData.title,
        positiveExperience: positiveReflectionData.positive_experience,
        specificEmotion: positiveReflectionData.specific_emotion,
        situationAnalysis: positiveReflectionData.situation_analysis,
        coreValue: positiveReflectionData.core_value,
        similarExperience: positiveReflectionData.similar_experience,
        relatedActivity: positiveReflectionData.related_activity,
        keywordPositiveValue:
          positiveReflectionData.reflection_keyword.positive_value,
        keywordPositiveEmotion:
          positiveReflectionData.reflection_keyword.positive_emotion,
        keywordActivity: positiveReflectionData.reflection_keyword.activity,
      });
    }
  }, [positiveReflectionData, reset]);

  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IEditPosExpReflectionSuccess,
    IEditPosExpReflectionError,
    IEditPosExpReflectionVariables
  >({
    mutationFn: editPosExpReflection,
    onSuccess: () => {
      toast({
        title: `수정이 완료되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      navigate(`/reflection/experiences/positive/${pk}`);
    },
    onError: (error) => console.log(error),
  });

  const inputPositiveValue = watch("keywordPositiveValue");
  const inputPositiveEmotion = watch("keywordPositiveEmotion");
  const inputActivity = watch("keywordActivity");

  const onSubmit = ({
    title,
    positiveExperience,
    specificEmotion,
    situationAnalysis,
    coreValue,
    similarExperience,
    relatedActivity,
    keywordPositiveValue,
    keywordPositiveEmotion,
    keywordActivity,
  }: IForm) => {
    if (pk) {
      mutation.mutate({
        pk: parseInt(pk),
        title,
        positive_experience: positiveExperience,
        specific_emotion: specificEmotion,
        situation_analysis: situationAnalysis,
        core_value: coreValue,
        similar_experience: similarExperience,
        related_activity: relatedActivity,
        reflection_keyword: {
          positive_value: keywordPositiveValue,
          positive_emotion: keywordPositiveEmotion,
          activity: keywordActivity,
        },
      });
    }
  };

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/experiences/positive/${pk}`);
  };

  return (
    <Container>
      <Flex
        py="5"
        px="1"
        onClick={onCancelAlertOpen}
        _hover={{ cursor: "pointer" }}
      >
        <Icon as={FaChevronLeft} />
      </Flex>

      <AlertDialog
        isCentered
        isOpen={isCancelAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancelAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {positiveReflectionData?.title} 닫기
            </AlertDialogHeader>
            <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancelAlertClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleCancel} ml={3}>
                닫기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack>
        <Tabs
          index={tabIndex}
          isFitted
          w="100%"
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab>1</Tab>
            <Tab>2</Tab>
            <Tab>3</Tab>
            <Tab>4</Tab>
            <Tab>5</Tab>
            <Tab>6</Tab>
            <Tab>7</Tab>
          </TabList>
          {isLoading ? (
            "로딩중.."
          ) : (
            <TabPanels as="form" onSubmit={handleSubmit(onSubmit)}>
              <TabPanel>
                <VStack>
                  <Heading mb="1">긍정경험 떠올리기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          좋았던 경험 하나를 떠올려보세요!
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 지하철에서 음악을 듣는데 좋았다."
                          {...register("positiveExperience")}
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}>
                          설레서 잠이 안왔던 경험 , 행복했던 경험
                          <br />
                          짜릿했던 경험, 기뻤던 경험
                          <br />
                          즐거웠던 경험, 번뜩였던 경험
                          <br />
                          뿌듯했던 경험, 몰입했던 경험
                          <br /> 가슴이 꽉찼던 경험, 신났던 경험
                          <br /> 삶에 활기가 생겼던 경험
                          <br /> 열정을 불태웠던 경험
                          <br />
                          재밌었던 경험, 신선한 충격을 받았던 경험
                          <br />
                          호기심이 생겼던 경험
                          <br /> 희망적이었던 경험
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem></GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(1)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">감정 구체화하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 경험에서 구체적으로 <br />
                          어떤 감정을 느꼈나요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 신남, 아련함, 충만감"
                          {...register("specificEmotion")}
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}>
                          기쁨 만족 희열 즐거움 감동 <br />
                          흐뭇함 번뜩임 고마움 성취감 연대감 <br />
                          따뜻함 편안함 황홀감 몰입감 충만감 <br />
                          전율 짜릿함 놀라움 신남 평온함 <br />
                          활기 열정 재밌음 카타르시스 <br />
                          신선한 충격 해소됨 <br />
                          호기심 희망 경탄 설렘
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(0)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(2)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">상황 분석하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          경험의 어떤 부분이 <br />그 감정을 일으켰나요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 음악의 멜로디와 가사"
                          {...register("situationAnalysis")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(1)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(3)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">가치를 단어로 표현하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 부분을 가치로 표현한다면 <br />
                          어떤 단어가 좋을까요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 감각적 아름다움, 서사"
                          {...register("coreValue")}
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}>
                          사랑 평화 화합 조화 연결 <br />
                          정의 평등 관용 존중 공존 <br />
                          창조 혁신 탐구 탐험 창의성 <br />
                          성장 변화 도전 강인함 유연함 <br />
                          온전함 정교함 통찰 지혜 독창성 <br />
                          신뢰 연대 협동 헌신 희생 <br />
                          겸손 정직 책임감 용서 자기수양 <br />
                          인내 강인함 배려 공감 친절 <br />
                          감화 인정 나눔 감사 <br />
                          자유 자율성 평정심 안정
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(2)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(4)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">비슷한 경험 떠올리기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          비슷한 가치와 감정을 느낀 <br />
                          다른 경험들도 작성해보세요!
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex)&#10;1) 소설이 주는 서사에 감동, 아련함을 느낀 경험이 있다.&#10;2) 영화가 주는 감각적 아름다움과 서사에 몰입감을 느낀 경험이 있다."
                          {...register("similarExperience")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(3)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(5)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">다른 활동들과 연결하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          비슷한 가치와 감정을 또 느끼려면 <br />
                          어떤 활동들을 해보는 게 좋을까요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 미술 전시회 감상"
                          {...register("relatedActivity")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(4)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(6)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">성찰 정리</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          다음의 3가지 키워드를 완성해보세요!
                        </Text>
                        <Divider />
                        <FormControl>
                          <FormLabel>나에게 의미있는 가치</FormLabel>
                          <Input
                            textAlign={"center"}
                            placeholder="ex) 감각적 아름다움, 서사"
                            {...register("keywordPositiveValue")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>그 가치에 내가 느끼는 감정</FormLabel>
                          <Input
                            textAlign={"center"}
                            placeholder="ex) 신남, 아련함, 몰입감"
                            {...register("keywordPositiveEmotion")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>그 가치를 실천할 수 있는 활동들</FormLabel>
                          <Input
                            textAlign={"center"}
                            placeholder="ex) 노래 듣기, 소설 읽기, 영화 감상, 미술 전시회 감상"
                            {...register("keywordActivity")}
                          />
                        </FormControl>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          나의 성찰
                        </Text>
                        <Divider />
                        <Text textAlign={"center"} lineHeight={2}>
                          나는{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveValue || "감각적 아름다움, 서사"}
                          </Text>{" "}
                          에{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveEmotion || "신남, 아련함, 몰입감"}
                          </Text>{" "}
                          을(를) 느낀다.
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          나의 추구
                        </Text>
                        <Divider />
                        <Text textAlign={"center"} lineHeight={2}>
                          나는{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputActivity ||
                              "노래 듣기, 소설 읽기, 영화 감상, 미술 전시회 감상"}
                          </Text>{" "}
                          을(를) 통해{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveValue || "감각적 아름다움, 서사"}
                          </Text>{" "}
                          을(를) 추구한다.
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          이번 성찰의 제목을 작성해주세요!
                        </Text>
                        <Input
                          textAlign={"center"}
                          placeholder="긍정경험 성찰"
                          {...register("title")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(5)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%"></GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
      </VStack>
    </Container>
  );
}

import {
  Avatar,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  ToastId,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import useUser from "../lib/useUser";
import { logOut } from "../routes/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const { userLoading, isLoggedIn, user } = useUser();
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toastId = useRef<ToastId>();
  const mutation = useMutation({
    mutationFn: logOut,
    onMutate: () => {
      toastId.current = toast({
        position: "top",
        title: "로그아웃 중..",
        description: "잠시만 기다려 주세요!",
        status: "loading",
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries({ queryKey: ["me"] });
        queryClient.refetchQueries({ queryKey: ["todos"] });
        queryClient.refetchQueries({ queryKey: ["identity"] });
        toast.update(toastId.current, {
          position: "top",
          title: "로그아웃이 완료되었습니다.",
          description: "또 봐요!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });
  const onLogOut = async () => {
    mutation.mutate();
  };
  return (
    <HStack justifyContent={"space-between"} py={3} px={5}>
      <Link to="/">
        <Flex alignItems={"center"}>
          <Text fontWeight={1000} mr="1">
            BeSelf
          </Text>
          <Text fontSize={12}>Beta</Text>
        </Flex>
      </Link>
      <HStack>
        <IconButton
          onClick={toggleColorMode}
          variant={"ghost"}
          aria-label="Toggle dark mode"
          icon={colorMode === "light" ? <FaSun /> : <FaMoon />}
        />
        {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Link to="/login">
                <Button>로그인</Button>
              </Link>
              <Link to="/sign-up">
                <Button>회원가입</Button>
              </Link>
            </>
          ) : (
            <Menu>
              <MenuButton>
                <Avatar size="sm" />
              </MenuButton>
              <MenuList>
                <Link to="/me">
                  <MenuItem>{user?.name}</MenuItem>
                </Link>
                <MenuItem onClick={onLogOut}>로그아웃</MenuItem>
              </MenuList>
            </Menu>
          )
        ) : null}
      </HStack>
    </HStack>
  );
}

import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Grid,
  GridItem,
  Icon,
  Skeleton,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import { getReflectionKeywords } from "./api";
import { Link } from "react-router-dom";
import ProtectedPage from "../components/ProtectedPage";
import {
  FaAngleRight,
  FaChevronLeft,
  FaFeatherAlt,
  FaLeaf,
} from "react-icons/fa";
import useUser from "../lib/useUser";
import {
  IReflectionKeywordPage,
  IReflectionKeywordPaginatedResponse,
} from "../types";
import { BeatLoader } from "react-spinners";

export default function MyPursuits() {
  const { isLoggedIn } = useUser();
  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<
      IReflectionKeywordPage,
      Error,
      IReflectionKeywordPaginatedResponse,
      QueryKey,
      number
    >({
      queryKey: ["reflectionKeywords"],
      queryFn: ({ pageParam = 1 }) => getReflectionKeywords(pageParam),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.next ? allPages.length + 1 : undefined;
      },
    });

  const { colorMode } = useColorMode();

  return (
    <ProtectedPage>
      <Container mb="10">
        <Flex>
          <Link to="/">
            <Flex py="5" px="1">
              <Icon as={FaChevronLeft} />
            </Flex>
          </Link>
        </Flex>
        <VStack>
          {isLoading ? (
            <>
              <Skeleton h="200px" w="100%" borderRadius={"lg"} />
              <Skeleton h="200px" w="100%" borderRadius={"lg"} />
              <Skeleton h="200px" w="100%" borderRadius={"lg"} />
              <Skeleton h="200px" w="100%" borderRadius={"lg"} />
              <Skeleton h="200px" w="100%" borderRadius={"lg"} />
            </>
          ) : (
            <>
              {data?.pages[0].count === 0 || !isLoggedIn ? (
                <>
                  <Card w="100%">
                    <CardBody>
                      <Flex w="100%" justifyContent={"center"}>
                        아직 성찰이 없습니다.
                      </Flex>
                    </CardBody>
                  </Card>
                  <Grid gridTemplateColumns={"1fr 1fr"} gap={3} w="100%">
                    <Link
                      style={{ width: "100%" }}
                      to={"/reflection/questions"}
                    >
                      <Card w="100%">
                        <Button
                          variant={"ghost"}
                          w="100%"
                          py="25"
                          colorScheme="yellow"
                        >
                          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                            <GridItem
                              display={"flex"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <Icon as={FaFeatherAlt} fontSize={14} />
                            </GridItem>
                            <GridItem
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Text>질문 성찰</Text>
                            </GridItem>
                            <GridItem
                              display={"flex"}
                              justifyContent={"flex-end"}
                              alignItems={"center"}
                            >
                              <Icon fontSize={15} as={FaAngleRight} />
                            </GridItem>
                          </Grid>
                        </Button>
                      </Card>
                    </Link>
                    <Link
                      style={{ width: "100%" }}
                      to={"/reflection/experiences"}
                    >
                      <Card w="100%">
                        <Button
                          variant={"ghost"}
                          w="100%"
                          py="25"
                          colorScheme="green"
                        >
                          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                            <GridItem
                              display={"flex"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <Icon as={FaLeaf} fontSize={14} />
                            </GridItem>
                            <GridItem
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Text>경험 성찰</Text>
                            </GridItem>
                            <GridItem
                              display={"flex"}
                              justifyContent={"flex-end"}
                              alignItems={"center"}
                            >
                              <Icon fontSize={15} as={FaAngleRight} />
                            </GridItem>
                          </Grid>
                        </Button>
                      </Card>
                    </Link>
                  </Grid>
                </>
              ) : (
                <>
                  {data?.pages.map((page, index) => (
                    <VStack key={index} w="100%">
                      {page.results.map((reflectionKeyword, index) => (
                        <Box key={index} w="100%">
                          {reflectionKeyword.type === "positive" ? (
                            <Link
                              to={
                                reflectionKeyword.question_reflection_pk
                                  ? `/reflection/questions/${reflectionKeyword.question_pk}/positive/${reflectionKeyword.question_reflection_pk}`
                                  : `/reflection/experiences/positive/${reflectionKeyword.experience_reflection_pk}`
                              }
                              key={index}
                              style={{ width: "100%" }}
                            >
                              <Card w="100%">
                                <CardBody>
                                  <VStack textAlign={"center"}>
                                    <Text>나는</Text>
                                    <Text fontSize={18} fontWeight={"900"}>
                                      {reflectionKeyword.activity}
                                    </Text>
                                    <Text>을(를) 통해</Text>
                                    <Text fontSize={18} fontWeight={"900"}>
                                      {reflectionKeyword.positive_value}
                                    </Text>
                                    <Text>을(를) 추구한다.</Text>
                                  </VStack>
                                </CardBody>
                              </Card>
                            </Link>
                          ) : (
                            <Link
                              to={
                                reflectionKeyword.question_reflection_pk
                                  ? `/reflection/questions/${reflectionKeyword.question_pk}/negative/${reflectionKeyword.question_reflection_pk}`
                                  : `/reflection/experiences/negative/${reflectionKeyword.experience_reflection_pk}`
                              }
                              key={index}
                              style={{ width: "100%" }}
                            >
                              <Card w="100%">
                                <CardBody>
                                  <VStack textAlign={"center"}>
                                    <Text>나는</Text>
                                    <Text fontSize={18} fontWeight={"900"}>
                                      {reflectionKeyword.activity}
                                    </Text>
                                    <Text>을(를) 통해</Text>
                                    <Text fontSize={18} fontWeight={"900"}>
                                      {reflectionKeyword.positive_value}
                                    </Text>
                                    <Text>을(를) 추구한다.</Text>
                                  </VStack>
                                </CardBody>
                              </Card>
                            </Link>
                          )}
                        </Box>
                      ))}
                    </VStack>
                  ))}
                </>
              )}
            </>
          )}
          {hasNextPage ? (
            <Button
              mt="3"
              spinner={
                <BeatLoader
                  color={colorMode === "light" ? "black" : "white"}
                  size="8px"
                />
              }
              onClick={() => {
                fetchNextPage();
              }}
              isLoading={isFetchingNextPage}
            >
              더 보기
            </Button>
          ) : null}
        </VStack>
      </Container>
    </ProtectedPage>
  );
}

import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FaEnvelope } from "react-icons/fa";
import {
  findPassword,
  findUsername,
  IFindPasswordError,
  IFindPasswordSuccess,
  IFindPasswordVariables,
  IFindUsernameError,
  IFindUsernameSuccess,
  IFindUsernameVariables,
} from "./api";
import { useState } from "react";
import LoginProtectedPage from "../components/LoginProtectedPage";
import { useParams } from "react-router-dom";

interface IForm {
  email: string;
}

export default function FindPassword() {
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IForm>();
  const mutation = useMutation<
    IFindPasswordSuccess,
    IFindPasswordError,
    IFindPasswordVariables
  >({
    mutationFn: findPassword,
    onError: (error) => setErrorMessage(() => error.response.data.detail),
  });

  const onSubmit = ({ email }: IForm) => {
    mutation.mutate({ email });
  };

  const inputEmail = watch("email");

  return (
    <LoginProtectedPage>
      <Container>
        <VStack mb={5}>
          <Text fontWeight={"700"} fontSize={30}>
            비밀번호 찾기
          </Text>
        </VStack>
        <Card as="form" onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.400">
                    <FaEnvelope />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "이메일을 입력해주세요",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "유효한 이메일 주소를 입력해주세요.",
                  },
                })}
                variant={"filled"}
                placeholder="이메일"
              />
            </InputGroup>
            {errors.email ? (
              <Text width={"100%"} textAlign={"left"} fontSize={"sm"} mt="1">
                {errors.email.message}
              </Text>
            ) : null}
            <Text mt="1">{errorMessage}</Text>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              mt={4}
              colorScheme="orange"
              w={"100%"}
            >
              비밀번호 찾기
            </Button>
            {mutation.isSuccess ? (
              <VStack
                p={5}
                mt="5"
                bg="green.100"
                borderRadius={5}
                color="black"
              >
                <Text fontWeight={"700"}>
                  이메일로 전송된 링크를 확인하세요.
                </Text>
                <Text>{inputEmail}</Text>
                <Button
                  colorScheme="green"
                  as="a"
                  href={`https://${inputEmail.split("@")[1]}`}
                  target="_blank"
                >
                  {inputEmail.split("@")[1]} 으로 이동하기 →
                </Button>
              </VStack>
            ) : null}
          </CardBody>
        </Card>
      </Container>
    </LoginProtectedPage>
  );
}

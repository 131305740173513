import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight, FaChevronLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editNegExpReflection,
  getNegativeExperience,
  IEditNegExpReflectionError,
  IEditNegExpReflectionSuccess,
  IEditNegExpReflectionVariables,
} from "./api";
import { INegativeExperienceReflection } from "../types";

interface IForm {
  negativeExperience: string;
  specificEmotion: string;
  situationAnalysis: string;
  negativeValue: string;
  similarExperience: string;
  positiveValue: string;
  relatedActivity: string;
  keywordNegativeValue: string;
  keywordNegativeEmotion: string;
  keywordPositiveValue: string;
  keywordActivity: string;
  title: string;
}

export default function EditNegExperience() {
  const { pk } = useParams();
  const { isLoading, data: negExpReflection } = useQuery<
    INegativeExperienceReflection,
    Error
  >({
    queryKey: ["negativeExperience", pk],
    queryFn: getNegativeExperience,
  });

  const { register, watch, handleSubmit, reset } = useForm<IForm>();

  useEffect(() => {
    if (negExpReflection) {
      reset({
        negativeExperience: negExpReflection.negative_experience,
        specificEmotion: negExpReflection.specific_emotion,
        situationAnalysis: negExpReflection.situation_analysis,
        negativeValue: negExpReflection.negative_value,
        similarExperience: negExpReflection.similar_experience,
        positiveValue: negExpReflection.positive_value,
        relatedActivity: negExpReflection.related_activity,
        keywordNegativeValue:
          negExpReflection.reflection_keyword.negative_value,
        keywordNegativeEmotion:
          negExpReflection.reflection_keyword.negative_emotion,
        keywordPositiveValue:
          negExpReflection.reflection_keyword.positive_value,
        keywordActivity: negExpReflection.reflection_keyword.activity,
        title: negExpReflection.title,
      });
    }
  }, [negExpReflection, reset]);

  const [tabIndex, setTabIndex] = useState(0);
  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IEditNegExpReflectionSuccess,
    IEditNegExpReflectionError,
    IEditNegExpReflectionVariables
  >({
    mutationFn: editNegExpReflection,
    onSuccess: () => {
      toast({
        title: `수정이 완료되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      navigate(`/reflection/experiences/negative/${pk}`);
    },
    onError: (error) => console.log(error),
  });

  const inputNegativeValue = watch("keywordNegativeValue");
  const inputNegativeEmotion = watch("keywordNegativeEmotion");
  const inputPositiveValue = watch("keywordPositiveValue");
  const inputActivity = watch("keywordActivity");

  const onSubmit = ({
    negativeExperience,
    specificEmotion,
    situationAnalysis,
    negativeValue,
    similarExperience,
    positiveValue,
    relatedActivity,
    keywordNegativeValue,
    keywordNegativeEmotion,
    keywordPositiveValue,
    keywordActivity,
    title,
  }: IForm) => {
    if (pk) {
      mutation.mutate({
        pk: parseInt(pk),
        title: title,
        negative_experience: negativeExperience,
        specific_emotion: specificEmotion,
        situation_analysis: situationAnalysis,
        negative_value: negativeValue,
        similar_experience: similarExperience,
        positive_value: positiveValue,
        related_activity: relatedActivity,
        reflection_keyword: {
          negative_value: keywordNegativeValue,
          negative_emotion: keywordNegativeEmotion,
          positive_value: keywordPositiveValue,
          activity: keywordActivity,
        },
      });
    }
  };

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/experiences/negative/${pk}`);
  };

  return (
    <Container>
      <Flex
        py="5"
        px="1"
        onClick={onCancelAlertOpen}
        _hover={{ cursor: "pointer" }}
      >
        <Icon as={FaChevronLeft} />
      </Flex>
      <AlertDialog
        isCentered
        isOpen={isCancelAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancelAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {negExpReflection?.title} 닫기
            </AlertDialogHeader>
            <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancelAlertClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleCancel} ml={3}>
                닫기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack>
        <Tabs
          index={tabIndex}
          isFitted
          w="100%"
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab>1</Tab>
            <Tab>2</Tab>
            <Tab>3</Tab>
            <Tab>4</Tab>
            <Tab>5</Tab>
            <Tab>6</Tab>
            <Tab>7</Tab>
            <Tab>8</Tab>
          </TabList>
          {isLoading ? (
            "로딩중.."
          ) : (
            <TabPanels as="form" onSubmit={handleSubmit(onSubmit)}>
              <TabPanel>
                <VStack>
                  <Heading mb="1">부정경험 떠올리기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          안좋았던 경험 하나를 떠올려보세요.
                        </Text>
                        <Textarea
                          {...register("negativeExperience")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 직장 동료에게 책에서 읽은 내용을 전해줬는데, 내 말을 들어주지 않아 답답했다."
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}></Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem></GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(1)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">감정 구체화하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 경험에서 구체적으로 <br />
                          어떤 감정을 느꼈나요?
                        </Text>
                        <Textarea
                          {...register("specificEmotion")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 답답함"
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}>
                          분노, 적대감, 증오, 경멸, 질투 <br />
                          삐침, 두려움, 불안, 초조함, 염려 <br />
                          절박함, 공포, 실망, 서운함, 낙담 <br />
                          괴로움, 자괴감, 좌절, 우울함, 침울함 <br />
                          무기력함, 피폐함, 허무함, 자포자기, 고립감 <br />
                          소외감, 쓸쓸함, 적막감, 단절감, 수치심 <br />
                          죄책감, 후회, 억울함, 초라함, 실의 <br />
                          혼란, 답답함, 혼돈, 무모함, 황당 <br />
                          당황, 비참함, 불쾌감, 무력감, 의기소침 <br />
                          피곤함, 배신감, 속상함, 도망치고 싶음, 절망
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(0)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(2)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">상황 분석하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          경험의 어떤 부분이 <br />그 감정을 일으켰나요?
                        </Text>
                        <Textarea
                          {...register("situationAnalysis")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 직장동료가 변화에 대한 의지가 없다고 느껴서 그랬던 거 같다."
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(1)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(3)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">단어로 표현하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 부분을 부정가치로 표현한다면 <br />
                          어떤 단어가 적절할까요?
                        </Text>
                        <Textarea
                          {...register("negativeValue")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 정체됨, 퇴보"
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}>
                          정체됨, 편견, 독단, 억압, 구속 <br />
                          불공정, 차별, 불평등, 분열, 충돌 <br />
                          냉담, 단절, 소외, 고립, 비겁함 <br />
                          무책임, 보수적, 무지, 무관심, 비관 <br />
                          갈등, 퇴보, 무시, 경멸, 탐욕 <br />
                          이기심, 포기, 회피, 고착, 고집 <br />
                          결핍, 조잡함, 배제, 무지, 피상적 <br />
                          획일성, 틀에 박힘, 무질서, 위선, 지배 <br />
                          폭력성, 불균형, 혼란, 파괴, 고정관념
                          <br />
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(2)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(4)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">비슷한 경험 떠올리기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          비슷한 부정가치와 감정을 느낀 <br />
                          다른 경험들이 있나요?
                        </Text>
                        <Textarea
                          {...register("similarExperience")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 나 스스로 정체된 것에 무기력함을 느낀 경험이 있다."
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(3)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(5)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">긍정가치 고민해보기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          여기서 알 수 있는 <br />
                          나에게 의미있는 긍정가치는 <br />
                          무엇인가요?
                        </Text>
                        <Textarea
                          {...register("positiveValue")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 성장, 변화"
                        />
                        <Text textAlign={"center"} mt="5" lineHeight={2}>
                          사랑 평화 화합 조화 연결 <br />
                          정의 평등 관용 존중 공존 <br />
                          창조 혁신 탐구 탐험 창의성 <br />
                          성장 변화 도전 강인함 유연함 <br />
                          온전함 정교함 통찰 지혜 독창성 <br />
                          신뢰 연대 협동 헌신 희생 <br />
                          겸손 정직 책임감 용서 자기수양 <br />
                          인내 강인함 배려 공감 친절 <br />
                          감화 인정 나눔 감사 <br />
                          자유 자율성 평정심 안정
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(4)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(6)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">긍정가치 연결하기</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 긍정가치를 실천할 수 있는 <br />
                          활동들엔 어떤 게 있나요? <br />
                        </Text>
                        <Textarea
                          {...register("relatedActivity")}
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 독서, 글쓰기, 건설적인 토론"
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(5)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(7)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Heading mb="1">성찰 정리</Heading>
                  <Divider mb="5" />
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          다음의 4가지 키워드를 완성해보세요!
                        </Text>
                        <Divider />
                        <FormControl>
                          <FormLabel>나에게 해로운 부정가치</FormLabel>
                          <Input
                            placeholder="ex) 정체됨, 퇴보"
                            {...register("keywordNegativeValue")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>부정가치에 내가 느끼는 감정</FormLabel>
                          <Input
                            placeholder="ex) 답답함, 무기력함"
                            {...register("keywordNegativeEmotion")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>나에게 의미있는 긍정가치</FormLabel>
                          <Input
                            placeholder="ex) 성장, 변화"
                            {...register("keywordPositiveValue")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            긍정가치를 실천할 수 있는 활동들
                          </FormLabel>
                          <Input
                            placeholder="ex) 독서, 글쓰기, 건설적인 토론"
                            {...register("keywordActivity")}
                          />
                        </FormControl>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          나의 성찰
                        </Text>
                        <Divider />
                        <Text textAlign={"center"} lineHeight={2}>
                          나는{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputNegativeValue
                              ? inputNegativeValue
                              : "정체됨, 퇴보"}
                          </Text>{" "}
                          에{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputNegativeEmotion
                              ? inputNegativeEmotion
                              : "답답함, 무기력함"}
                          </Text>{" "}
                          을(를) 느낀다.
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          나의 추구
                        </Text>
                        <Divider />
                        <Text textAlign={"center"} lineHeight={2}>
                          나는{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputActivity
                              ? inputActivity
                              : "독서, 글쓰기, 건설적인 토론"}
                          </Text>{" "}
                          을(를) 통해{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveValue
                              ? inputPositiveValue
                              : "성장, 변화"}
                          </Text>{" "}
                          을(를) 추구한다.
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          이번 성찰의 제목을 작성해주세요!
                        </Text>
                        <Input
                          textAlign={"center"}
                          placeholder="부정경험 성찰"
                          {...register("title")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(6)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        w="100%"
                        isLoading={mutation.isPending}
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%"></GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
      </VStack>
    </Container>
  );
}

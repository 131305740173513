import {
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Skeleton,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import {
  FaAngleRight,
  FaCloud,
  FaLeaf,
  FaLightbulb,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { getExperienceReflections } from "./api";
import { QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import useUser from "../lib/useUser";
import { IExpReflectionPage, IExpReflectionPaginatedResponse } from "../types";
import { BeatLoader } from "react-spinners";
import TermsAgreementProtected from "../components/TermsAgreementProtected";
import EmailVerificationProtected from "../components/EmailVerificationProtected";

export default function Experiences() {
  const { isLoggedIn } = useUser();
  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<
      IExpReflectionPage,
      Error,
      IExpReflectionPaginatedResponse,
      QueryKey,
      number
    >({
      queryKey: ["experienceReflections"],
      queryFn: ({ pageParam = 1 }) => getExperienceReflections(pageParam),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.next ? allPages.length + 1 : undefined;
      },
      retry: false,
    });
  const { colorMode } = useColorMode();

  return (
    <TermsAgreementProtected>
      <EmailVerificationProtected>
        <Container>
          <Grid gridTemplateColumns={"1fr 1fr"} gap={3}>
            <Link style={{ width: "100%" }} to={"positive/upload"}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25" colorScheme="green">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Icon as={FaLeaf} fontSize={14} />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      <Text>긍정경험</Text>
                    </GridItem>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Icon fontSize={15} as={FaPlus} />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
            <Link style={{ width: "100%" }} to={"negative/upload"}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25" colorScheme="blue">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Icon as={FaCloud} fontSize={14} />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      <Text>부정경험</Text>
                    </GridItem>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Icon fontSize={15} as={FaPlus} />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
          </Grid>
          <Divider my="5" />
          <Card w="100%" mb="2">
            <Link to="/reflection/guide" style={{ width: "100%" }}>
              <Button w="100%" py="25" variant="ghost">
                <Grid w="100%" gridTemplateColumns={"1fr 5fr 1fr"}>
                  <GridItem
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <FaLightbulb />
                  </GridItem>
                  <GridItem display={"flex"} justifyContent={"center"}>
                    비셀프 성찰 완벽 가이드
                  </GridItem>
                  <GridItem
                    fontSize={20}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <FaAngleRight />
                  </GridItem>
                </Grid>
              </Button>
            </Link>
          </Card>
          {isLoading ? (
            <>
              <VStack>
                <Skeleton h="85px" w="100%" borderRadius={"lg"} />
                <Skeleton h="85px" w="100%" borderRadius={"lg"} />
                <Skeleton h="85px" w="100%" borderRadius={"lg"} />
                <Skeleton h="85px" w="100%" borderRadius={"lg"} />
                <Skeleton h="85px" w="100%" borderRadius={"lg"} />
              </VStack>
            </>
          ) : (
            <VStack w="100%">
              {data?.pages[0].count === 0 || !isLoggedIn ? (
                <Card w="100%">
                  <CardBody>
                    <Flex w="100%" justifyContent={"center"}>
                      아직 경험성찰이 없습니다.
                    </Flex>
                  </CardBody>
                </Card>
              ) : (
                <>
                  {data?.pages.map((page, index) => (
                    <VStack key={index} w="100%">
                      {page.results.map((experienceReflection, index) => (
                        <Link
                          key={index}
                          to={`/reflection/experiences/${
                            experienceReflection.type === "positive"
                              ? "positive"
                              : "negative"
                          }/${experienceReflection.pk}`}
                          style={{ width: "100%" }}
                        >
                          <Card w="100%">
                            <CardBody>
                              <Text fontWeight={600} textAlign={"center"}>
                                {experienceReflection.title}
                              </Text>
                              <Text
                                fontSize={14}
                                color="gray.500"
                                textAlign={"center"}
                              >
                                {experienceReflection.updated_at}
                              </Text>
                            </CardBody>
                          </Card>
                        </Link>
                      ))}
                    </VStack>
                  ))}
                </>
              )}
              {hasNextPage ? (
                <Button
                  mt="3"
                  spinner={
                    <BeatLoader
                      color={colorMode === "light" ? "black" : "white"}
                      size="8px"
                    />
                  }
                  onClick={() => {
                    fetchNextPage();
                  }}
                  isLoading={isFetchingNextPage}
                >
                  더 보기
                </Button>
              ) : null}
            </VStack>
          )}
        </Container>
      </EmailVerificationProtected>
    </TermsAgreementProtected>
  );
}

import { Text } from "@chakra-ui/react";

export default function PositiveEmotions() {
  return (
    <Text textAlign={"center"} mt="5" lineHeight={2}>
      기쁨 만족 희열 즐거움 감동 <br />
      흐뭇함 번뜩임 고마움 성취감 연대감 <br />
      따뜻함 편안함 황홀감 몰입감 충만감 <br />
      전율 짜릿함 놀라움 신남 평온함 <br />
      활기 열정 재밌음 카타르시스 <br />
      신선한 충격 해소됨 <br />
      호기심 희망 경탄 설렘
    </Text>
  );
}

import { Text } from "@chakra-ui/react";

export default function NegativeValues() {
  return (
    <Text textAlign={"center"} mt="5" lineHeight={2}>
      정체됨, 편견, 독단, 억압, 구속 <br />
      불공정, 차별, 불평등, 분열, 충돌 <br />
      냉담, 단절, 소외, 고립, 비겁함 <br />
      무책임, 보수적, 무지, 무관심, 비관 <br />
      갈등, 퇴보, 무시, 경멸, 탐욕 <br />
      이기심, 포기, 회피, 고착, 고집 <br />
      결핍, 조잡함, 배제, 무지, 피상적 <br />
      획일성, 틀에 박힘, 무질서, 위선, 지배 <br />
      폭력성, 불균형, 혼란, 파괴, 고정관념
      <br />
    </Text>
  );
}

import {
  Card,
  CardBody,
  Container,
  Flex,
  Icon,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getReflectionQuestions } from "./api";
import { useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import ProtectedPage from "../components/ProtectedPage";

interface IPosReflectionQuestionList {
  pk: number;
  title: string;
  type: "positive" | "nagative";
}

export default function SelectQuestion() {
  const { isLoading, data: reflectionQuestions } = useQuery<
    IPosReflectionQuestionList[],
    Error
  >({
    queryKey: ["reflectionQuestions"],
    queryFn: getReflectionQuestions,
  });
  //   useEffect(() => console.log(reflectionQuestions), [reflectionQuestions]);
  return (
    <Container>
      <Flex>
        <Link to="/reflection/questions">
          <Flex py="5" px="1">
            <Icon as={FaChevronLeft} />
          </Flex>
        </Link>
      </Flex>
      {isLoading ? (
        <>
          <VStack>
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
            <Skeleton h="65px" w="100%" borderRadius={"lg"} />
          </VStack>
        </>
      ) : (
        <VStack w="100%" mb="5">
          {reflectionQuestions?.map((reflectionQuestion, index) => (
            <Card key={index} w="100%">
              <Link
                to={`${
                  reflectionQuestion.type === "positive"
                    ? "positive"
                    : "negative"
                }/${reflectionQuestion.pk}`}
              >
                <CardBody textAlign={"center"}>
                  Q{index + 1}. {reflectionQuestion.title}
                </CardBody>
              </Link>
            </Card>
          ))}
        </VStack>
      )}
    </Container>
  );
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Progress,
  ScaleFade,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Draggable, DraggableProvided, Droppable } from "@hello-pangea/dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  FaAngleDown,
  FaAngleRight,
  FaAngleUp,
  FaBars,
  FaCheckCircle,
  FaPlus,
  FaRegCircle,
  FaTrash,
} from "react-icons/fa";
import {
  ICompleteTaskError,
  ICompleteTaskSuccess,
  ICompleteTaskVariables,
  IDeleteTodoGroupError,
  IDeleteTodoGroupVariables,
  IUpdateTasksCompletedError,
  IUpdateTasksCompletedSuccess,
  IUpdateTasksCompletedVariables,
  completeTask,
  deleteTodoGroup,
  getTasks,
  updateGroupName,
  updateTasksCompleted,
} from "../routes/api";
import { ITask, ITodoGroup } from "../routes/Todolist";
import TaskListSkeleton from "./TaskListSkeleton";
import TaskItem from "./TaskItem";

interface ITodoCardProps {
  todoGroup: ITodoGroup;
  setTasksForModal: React.Dispatch<React.SetStateAction<ITask[]>>;
  handleOpenModal: (taskPk: number | null, TodoGroupPk: number | null) => void;
  isGroupEditing: boolean;
  groupProvided: DraggableProvided;
  isAnyDragging: boolean;
}

export default function TodoCard({
  todoGroup,
  handleOpenModal,
  isGroupEditing,
  groupProvided,
  setTasksForModal,
  isAnyDragging,
}: ITodoCardProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const { isLoading: isTasksLoading, data: tasksData } = useQuery<
    ITask[],
    Error
  >({
    queryKey: ["tasks", todoGroup.pk],
    queryFn: getTasks,
    enabled: !isCollapsed,
  });

  const completeTaskMutation = useMutation<
    ICompleteTaskSuccess,
    ICompleteTaskError,
    ICompleteTaskVariables
  >({
    mutationFn: completeTask,
    onSuccess: (updatedTask) => {
      queryClient.setQueryData<ITask[]>(["tasks", todoGroup.pk], (oldTasks) => {
        if (oldTasks) {
          const filteredTasks = oldTasks.filter(
            (task) => task.pk !== updatedTask.pk
          );
          const newTasks = [...filteredTasks, updatedTask];
          const orderedTasks = newTasks.sort((a, b) => a.order - b.order);
          return orderedTasks;
        }
      });
    },
  });

  const queryClient = useQueryClient();

  const deleteGroupMutation = useMutation<
    any,
    IDeleteTodoGroupError,
    IDeleteTodoGroupVariables
  >({
    mutationFn: deleteTodoGroup,
    onSuccess: () => queryClient.refetchQueries({ queryKey: ["todos"] }),
    onError: (error) => console.log(error),
  });

  const updateTasksCompletedMutation = useMutation<
    IUpdateTasksCompletedSuccess,
    IUpdateTasksCompletedError,
    IUpdateTasksCompletedVariables
  >({
    mutationFn: updateTasksCompleted,
    onSuccess: () =>
      queryClient.refetchQueries({ queryKey: ["tasks", todoGroup.pk] }),
    onError: (error) => console.log(error),
  });

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const updateGroupNameMutation = useMutation({
    mutationFn: updateGroupName,
    onSuccess: (data) => queryClient.refetchQueries({ queryKey: ["todos"] }),
    onError: (error) => console.log(error),
  });

  const handleDeleteGroup = () => {
    deleteGroupMutation.mutate(
      { todoGroupPk: todoGroup.pk },
      {
        onSuccess: () => onAlertClose(),
      }
    );
  };

  const handleUpdateGroupName = (name: string) => {
    if (name !== todoGroup.name) {
      updateGroupNameMutation.mutate({ name, pk: todoGroup.pk });
    }
  };

  const addTask = () => {
    if (tasksData) {
      setTasksForModal(tasksData);
    }
    handleOpenModal(null, todoGroup.pk);
  };

  const handleCompleteTask = (pk: number) => {
    if (tasksData) {
      let isCompleted = false; // 작업 완료 상태 저장
      const newTasks = tasksData.map((task) => {
        if (task.pk === pk) {
          isCompleted = !task.completed; // 작업의 현재 완료 상태 반전
          return { ...task, completed: isCompleted };
        }
        return task;
      });
      queryClient.setQueryData<ITask[]>(
        ["tasks", todoGroup.pk],
        () => newTasks
      );

      // API 호출
      completeTaskMutation.mutate(
        { pk, completed: isCompleted },
        {
          onError: () => {
            // 에러 시 이전 상태로 롤백
            queryClient.refetchQueries({ queryKey: ["tasks", todoGroup.pk] });
          },
        }
      );
    }
  };

  const calculateProgress = () => {
    if (tasksData) {
      const totalTasks = tasksData.length;
      const completedTasks = tasksData?.filter((task) => task.completed).length;
      return totalTasks === 0
        ? 0
        : Math.round((completedTasks / totalTasks) * 100);
    } else {
      return 0;
    }
  };

  const progress = calculateProgress();

  const { colorMode } = useColorMode();

  const boxShadowLight = "0 0 5px rgba(0, 0, 0, 0.25)";
  const boxShadowDark = "0 0 5px rgba(255, 255, 255, 0.25)";

  const value = useColorModeValue("white", "gray.700");

  useEffect(() => {
    const savedState = localStorage.getItem(
      `todoGroup-${todoGroup.pk}-collapsed`
    );
    if (savedState !== null) {
      setIsCollapsed(JSON.parse(savedState));
    }
  }, [todoGroup.pk]);

  const toggleCollapse = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem(
      `todoGroup-${todoGroup.pk}-collapsed`,
      JSON.stringify(newState)
    );
  };

  useEffect(() => {
    if (tasksData) {
      const completedTasksCount = tasksData.filter(
        (task) => task.completed
      ).length;
      if (completedTasksCount === tasksData.length) {
        setIsAllCompleted(true);
      } else {
        setIsAllCompleted(false);
      }
    }
  }, [tasksData]);

  const handleCompleteAllTask = () => {
    if (tasksData) {
      if (!isAllCompleted) {
        // 전체 완료 상태가 아니면
        const updatedTasks = tasksData.map((task) => ({
          pk: task.pk,
          completed: true,
        }));
        updateTasksCompletedMutation.mutate({ tasks: updatedTasks });
      } else {
        const updatedTasks = tasksData.map((task) => ({
          pk: task.pk,
          completed: false,
        }));
        updateTasksCompletedMutation.mutate({ tasks: updatedTasks });
      }
    }
  };

  return (
    <Card w="100%" variant={"elevated"}>
      <CardHeader py="4">
        <Flex justifyContent={"space-between"}>
          <Grid w="100%" templateColumns="1fr 5fr 1fr">
            <GridItem alignContent={"center"} fontSize={"xl"}>
              {isGroupEditing ? (
                <>
                  <FaTrash
                    color={"red"}
                    cursor={"pointer"}
                    onClick={onAlertOpen}
                  />
                  <AlertDialog
                    isCentered
                    isOpen={isAlertOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onAlertClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {todoGroup.name} 삭제
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          그룹을 삭제하시겠습니까?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onAlertClose}>
                            취소
                          </Button>
                          <Button
                            isLoading={deleteGroupMutation.isPending}
                            colorScheme="red"
                            onClick={handleDeleteGroup}
                            ml={3}
                          >
                            삭제
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </>
              ) : (
                <Flex h="100%">
                  <Flex
                    h="100%"
                    cursor="pointer"
                    onClick={toggleCollapse}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Icon as={isCollapsed ? FaAngleDown : FaAngleUp} />
                  </Flex>
                </Flex>
              )}
            </GridItem>
            <Editable
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize="xl"
              width={"100%"}
              whiteSpace="nowrap"
              defaultValue={todoGroup.name}
              placeholder="새 그룹" // value가 없을 경우 input이 사라지는 것을 방지
            >
              <EditablePreview cursor={"pointer"} />
              <EditableInput
                onBlur={(e) => handleUpdateGroupName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default Enter key behavior
                    (e.target as HTMLInputElement).blur(); // Triggers the onBlur event
                  }
                }}
                textAlign={"center"}
              />
            </Editable>
            <GridItem
              {...groupProvided.dragHandleProps}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"flex-end"}
              alignContent={"flex-start"}
              fontSize={"xl"}
            >
              {isGroupEditing ? <FaBars /> : null}
            </GridItem>
          </Grid>
        </Flex>
      </CardHeader>
      <Collapse
        in={!isCollapsed && !isGroupEditing}
        animateOpacity
        unmountOnExit
      >
        <>
          <CardBody pt="0">
            <Box mt="2" w="100%">
              <Flex
                mb="2"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Text>해낸 일: {progress}%</Text>
                <Text>
                  {progress === 0
                    ? "Let's Go!"
                    : progress < 40
                    ? "Good!"
                    : progress < 70
                    ? "Great!!"
                    : "Excellent!!!"}
                </Text>
              </Flex>
              <Progress
                color="green.400"
                value={progress}
                size={"lg"}
                min={0}
                max={100}
                borderRadius="lg"
                hasStripe
                isIndeterminate={isTasksLoading}
                sx={{
                  "& > div:first-of-type": {
                    transitionProperty: "width",
                    transitionDuration: "0.5s",
                    transitionTimingFunction: "ease-in-out",
                  },
                }}
              />
            </Box>
            <Divider mt="5" mb="3" />
            {isTasksLoading ? (
              <>
                <TaskListSkeleton />
                <TaskListSkeleton />
                <TaskListSkeleton />
                <TaskListSkeleton />
                <TaskListSkeleton />
              </>
            ) : (
              <Box w="100%">
                <Droppable droppableId={`${todoGroup.pk}`} type="task">
                  {(provided) => (
                    <VStack
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      w="100%"
                      maxH="min-content"
                    >
                      {tasksData?.map((task, index) => (
                        <Draggable
                          key={task.pk}
                          draggableId={`${task.pk}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <HStack
                              bg={value}
                              borderRadius="lg"
                              px={1}
                              _hover={{
                                boxShadow:
                                  colorMode === "light"
                                    ? boxShadowLight
                                    : boxShadowDark,
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              w="100%"
                              justifyContent={"space-between"}
                              boxShadow={
                                snapshot.isDragging
                                  ? colorMode === "light"
                                    ? boxShadowLight
                                    : boxShadowDark
                                  : "none"
                              }
                            >
                              <TaskItem
                                todoGroup={todoGroup}
                                task={task}
                                handleOpenModal={handleOpenModal}
                                handleCompleteTask={handleCompleteTask}
                                isDragging={snapshot.isDragging}
                                isAnyDragging={isAnyDragging}
                              />
                            </HStack>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </VStack>
                  )}
                </Droppable>
              </Box>
            )}
          </CardBody>
          <CardFooter justifyContent={"center"}>
            <Grid w="100%" gridTemplateColumns={"1fr 1fr 1fr"}>
              <GridItem display={"flex"} alignItems={"center"} fontSize={"18"}>
                <Flex
                  onClick={handleCompleteAllTask}
                  cursor={"pointer"}
                  px="2"
                  h="100%"
                  alignItems={"center"}
                >
                  <ScaleFade
                    initialScale={0.5}
                    in={(tasksData?.length ?? 0) > 1}
                  >
                    {isAllCompleted ? <FaCheckCircle /> : <FaRegCircle />}
                  </ScaleFade>
                  {updateTasksCompletedMutation.isPending ? (
                    <Spinner left="25px" position={"absolute"} />
                  ) : null}
                </Flex>
              </GridItem>
              <GridItem
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {(tasksData?.length ?? 0) >= 30 ? null : (
                  <Button onClick={addTask}>
                    {tasksData?.length !== 0 ? (
                      <FaPlus />
                    ) : (
                      <Text>할 일 추가</Text>
                    )}
                  </Button>
                )}
              </GridItem>
              <GridItem></GridItem>
            </Grid>
          </CardFooter>
        </>
      </Collapse>
    </Card>
  );
}
